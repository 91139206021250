/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { fetchReciter, saveReciter } from '../../actions/reciters';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';

class ReciterEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.requestSubmit = this.requestSubmit.bind(this);
    this.failedSubmit = this.failedSubmit.bind(this);
  }

  componentWillMount() {
    const { getReciter } = this.props;
    getReciter();
  }

  handleChange(e, name) {
    const { value } = e.target;
    if (name === 'hidden' || name === 'featured') {
      this.setState({ [name]: value === 'true' });
      if (name === 'featured' && value === 'false') {
        this.setState({ featureCover: null });
        this.setState({ featuredTitleColor: null });
      }
    } else {
      this.setState({ [name]: value });
    }
  }

  handleFileChange(e, name) {
    const { files } = e.target;
    this.setState({ [name]: files[0] });
  }

  requestSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedSubmit() {
    this.setState({
      submitted: false,
    });
  }

  handleSubmit() {
    const { saveReciterAction, goToShow, resetErrorAction } = this.props;
    resetErrorAction();
    const {
      TitleEN,
      TitleAR,
      RecitorLabel,
      image_url,
      BiblioAr,
      BiblioEn,
      BiblioFr,
      Link,
      Title,
      order,
      hidden,
      featured,
      featureCover,
      featuredTitleColor,
      featuredOrder,
      timing,
      timingVersion,
      timingLink,
      partial,
      Facebook,
      Twitter,
      Instagram,
      Youtube,
      Snapchat,
    } = this.state;
    // if (!TitleEN && !TitleAR && !RecitorLabel && !image_url && !order
    //   && (hidden === undefined) && (featured === undefined) && !featureCover
    //   && !featuredTitleColor && !featuredOrder && !BiblioAr && !BiblioEn && !BiblioFr
    //   && !Link && !Title && !Facebook && !Twitter && !Instagram && !Youtube && !Snapchat) {
    //   goToShow();
    //   return;
    // }
    const body = {
      TitleEN,
      TitleAR,
      RecitorLabel,
      order,
      hidden,
      Facebook,
      Twitter,
      Instagram,
      Youtube,
      Snapchat,
      featured,
      featuredTitleColor,
      featuredOrder,
      timing,
      timingVersion,
      timingLink,
      partial,
      description: {
        BiblioAr,
        BiblioEn,
        BiblioFr,
        Link,
        Title,
      },
    };
    Object.keys(body.description)
      .forEach(key => body.description[key] === undefined && delete body.description[key]);
    if (!Object.keys(body.description).length) {
      delete body.description;
    }
    Object.keys(body).forEach(key => body[key] === undefined && delete body[key]);
    const { reciter } = this.props;
    body.description = {
      ...reciter.description,
      ...body.description,
    };
    const formData = new FormData();
    if (image_url) {
      formData.append('image_url', image_url);
    }
    if (featureCover) {
      formData.append('featureCover', featureCover);
    }
    if (featuredOrder) {
      formData.append('featuredOrder', featuredOrder);
    }
    if (timingVersion) {
      formData.append('timingVersion', timingVersion);
    }
    if (timingLink) {
      formData.append('timingLink', timingLink);
    }
    formData.append('body', JSON.stringify(body));
    saveReciterAction(reciter.reciter.Guid, formData).then(() => goToShow());
  }

  render() {
    const { reciter } = this.props;
    const {
      TitleEN,
      TitleAR,
      RecitorLabel,
      image_url,
      BiblioAr,
      BiblioEn,
      BiblioFr,
      Link,
      Title,
      order,
      featured,
      timing,
      Facebook,
      Twitter,
      Instagram,
      Youtube,
      Snapchat,
      submitted,
    } = this.state;
    if (!reciter || Object.keys(reciter).length === 0) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleSubmit} failedSubmit={this.failedSubmit} />
        }
        <Row>
          <Col lg={8}>
            <Card>
              <Form onSubmit={e => this.requestSubmit(e)} encType="multipart/form-data">
                <CardHeader>
                  <strong>
                    {'Edit reciter: '}
                    {reciter.reciter.Guid}
                  </strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="TitleEN">Title En</Label>
                    <Input
                      id="TitleEN"
                      type="text"
                      placeholder="Enter Title En"
                      defaultValue={reciter.reciter.TitleEN}
                      invalid={submitted && !TitleEN}
                      onChange={e => this.handleChange(e, 'TitleEN')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="TitleAR">Title Ar</Label>
                    <Input
                      id="TitleAR"
                      type="text"
                      placeholder="Enter Title Ar"
                      defaultValue={reciter.reciter.TitleAR}
                      invalid={submitted && !TitleAR}
                      onChange={e => this.handleChange(e, 'TitleAR')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="RecitorLabel">Reciter Label</Label>
                    <Input
                      id="RecitorLabel"
                      type="text"
                      placeholder="Enter Reciter Label"
                      defaultValue={reciter.reciter.RecitorLabel}
                      invalid={submitted && !RecitorLabel}
                      onChange={e => this.handleChange(e, 'RecitorLabel')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="hidden">Hidden</Label>
                    <Input
                      type="select"
                      name="hidden"
                      id="hidden"
                      onChange={e => this.handleChange(e, 'hidden')}
                      defaultValue={reciter.reciter.hidden}
                    >
                      <option>true</option>
                      <option>false</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="image_url">Image url</Label>
                    <Input
                      id="image_url"
                      type="text"
                      defaultValue={reciter.reciter.image_url}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="image_url">Upload new image</Label>
                    <Input
                      id="image_url"
                      type="file"
                      invalid={submitted && !image_url}
                      onChange={e => this.handleFileChange(e, 'image_url')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="featured">Featured</Label>
                    <Input
                      type="select"
                      name="featured"
                      id="featured"
                      onChange={e => this.handleChange(e, 'featured')}
                      defaultValue={reciter.reciter.featured}
                    >
                      <option>true</option>
                      <option>false</option>
                    </Input>
                  </FormGroup>
                  {
                    ((reciter.reciter.featured && featured === undefined)
                      || (featured !== undefined && featured))
                    && (
                    <>
                      <FormGroup>
                        <Label htmlFor="featureCover">Feature Cover</Label>
                        <Input
                          id="featureCover"
                          type="text"
                          defaultValue={reciter.reciter.featureCover}
                          disabled
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="featureCover">Upload new feature cover</Label>
                        <Input
                          id="featureCover"
                          type="file"
                          invalid={submitted}
                          onChange={e => this.handleFileChange(e, 'featureCover')}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="featuredTitleColor">Featured Title Color</Label>
                        <Input
                          id="featuredTitleColor"
                          type="text"
                          placeholder="Enter Featured Title Color"
                          defaultValue={reciter.reciter.featuredTitleColor}
                          invalid={submitted}
                          onChange={e => this.handleChange(e, 'featuredTitleColor')}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="order">Featured Order</Label>
                        <Input
                          id="featuredOrder"
                          type="number"
                          placeholder="Enter featured order"
                          defaultValue={reciter.reciter.featuredOrder}
                          invalid={submitted}
                          onChange={e => this.handleChange(e, 'featuredOrder')}
                        />
                      </FormGroup>
                    </>
                    )
                  }
                  <FormGroup>
                    <Label for="timing">Timing</Label>
                    <Input
                      type="select"
                      name="timing"
                      id="timing"
                      onChange={e => this.handleChange(e, 'timing')}
                      defaultValue={reciter.reciter.timing}
                    >
                      <option>true</option>
                      <option>false</option>
                    </Input>
                  </FormGroup>
                  {
                    ((reciter.reciter.timing && timing === undefined)
                      || (timing !== undefined && timing))
                    && (
                    <>
                      <FormGroup>
                        <Label htmlFor="timingVersion">Timing Version</Label>
                        <Input
                          id="timingVersion"
                          type="number"
                          placeholder="Enter timing version"
                          defaultValue={reciter.reciter.timingVersion}
                          invalid={submitted}
                          onChange={e => this.handleChange(e, 'timingVersion')}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="timingLink">Timing Link</Label>
                        <Input
                          id="timingLink"
                          type="text"
                          defaultValue={reciter.reciter.timingLink}
                          disabled
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="timingLink">Upload new timing link</Label>
                        <Input
                          id="timingLink"
                          type="file"
                          invalid={submitted}
                          onChange={e => this.handleFileChange(e, 'timingLink')}
                        />
                      </FormGroup>
                    </>
                    )
                  }
                  <FormGroup>
                    <Label for="partial">Partial</Label>
                    <Input
                      type="select"
                      name="partial"
                      id="partial"
                      onChange={e => this.handleChange(e, 'partial')}
                      defaultValue={reciter.reciter.partial}
                    >
                      <option>true</option>
                      <option>false</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="BiblioAr">Biblio Ar</Label>
                    <Input
                      id="BiblioAr"
                      type="textarea"
                      placeholder="Enter Biblio Ar"
                      value={BiblioAr || (reciter.description.BiblioAr)}
                      invalid={submitted && !BiblioAr}
                      onChange={e => this.handleChange(e, 'BiblioAr')}
                      rows="10"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="BiblioEn">Biblio En</Label>
                    <Input
                      id="BiblioEn"
                      type="textarea"
                      placeholder="Enter Biblio En"
                      value={BiblioEn || (reciter.description.BiblioEn)}
                      invalid={submitted && !BiblioEn}
                      onChange={e => this.handleChange(e, 'BiblioEn')}
                      rows="10"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="BiblioFr">Biblio Fr</Label>
                    <Input
                      id="BiblioFr"
                      type="textarea"
                      placeholder="Enter Biblio Fr"
                      value={BiblioFr || (reciter.description.BiblioFr)}
                      invalid={submitted && !BiblioFr}
                      onChange={e => this.handleChange(e, 'BiblioFr')}
                      rows="10"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="Link">Link</Label>
                    <Input
                      id="Link"
                      type="text"
                      placeholder="Enter Link"
                      defaultValue={reciter.description.Link}
                      invalid={submitted && !Link}
                      onChange={e => this.handleChange(e, 'Link')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="Title">Title</Label>
                    <Input
                      id="Title"
                      type="text"
                      placeholder="Enter Title"
                      defaultValue={reciter.description.Title}
                      invalid={submitted && !Title}
                      onChange={e => this.handleChange(e, 'Title')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="order">Order</Label>
                    <Input
                      id="order"
                      type="number"
                      placeholder="Enter order"
                      defaultValue={reciter.reciter.order}
                      invalid={submitted && !order}
                      onChange={e => this.handleChange(e, 'order')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="Facebook">Facebook</Label>
                    <Input
                      id="Facebook"
                      type="text"
                      placeholder="Enter Facebook"
                      defaultValue={reciter.reciter.Facebook}
                      invalid={submitted && !Facebook}
                      onChange={e => this.handleChange(e, 'Facebook')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="Twitter">Twitter</Label>
                    <Input
                      id="Twitter"
                      type="text"
                      placeholder="Enter Twitter"
                      defaultValue={reciter.reciter.Twitter}
                      invalid={submitted && !Twitter}
                      onChange={e => this.handleChange(e, 'Twitter')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="Instagram">Instagram</Label>
                    <Input
                      id="Instagram"
                      type="text"
                      placeholder="Enter Instagram"
                      defaultValue={reciter.reciter.Instagram}
                      invalid={submitted && !Instagram}
                      onChange={e => this.handleChange(e, 'Instagram')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="Youtube">Youtube</Label>
                    <Input
                      id="Youtube"
                      type="text"
                      placeholder="Enter Youtube"
                      defaultValue={reciter.reciter.Youtube}
                      invalid={submitted && !Youtube}
                      onChange={e => this.handleChange(e, 'Youtube')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="Snapchat">Snapchat</Label>
                    <Input
                      id="Snapchat"
                      type="text"
                      placeholder="Enter Snapchat"
                      defaultValue={reciter.reciter.Snapchat}
                      invalid={submitted && !Snapchat}
                      onChange={e => this.handleChange(e, 'Snapchat')}
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="success">Save</Button>
                  {' '}
                  <Button href={`/#/reciters/${reciter.reciter.Guid}`} size="sm" color="danger">Cancel</Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

ReciterEdit.propTypes = {
  getReciter: PropTypes.func.isRequired,
  goToShow: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
  saveReciterAction: PropTypes.func.isRequired,
  reciter: PropTypes.shape.isRequired,
};

const mapStateToProps = ({ reciters }) => ({
  reciter: reciters.item,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  getReciter: () => fetchReciter(ownProps.match.params.id),
  goToShow: () => push(`/reciters/${ownProps.match.params.id}`),
  resetErrorAction: () => resetError(),
  saveReciterAction: (id, body) => saveReciter(id, body),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReciterEdit);
