import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Row, Table,
} from 'reactstrap';
import cryptLib from '@skavinvarnan/cryptlib';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import { deleteTranslation, fetchTranslation } from '../../actions/translations';
import Can from '../../components/can';
import PinModal from '../../components/pin';

class Translation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.requestDelete = this.requestDelete.bind(this);
    this.failedDelete = this.failedDelete.bind(this);
  }

  componentWillMount() {
    const { getTranslation } = this.props;
    getTranslation();
  }

  requestDelete(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedDelete() {
    this.setState({
      submitted: false,
    });
  }

  handleDelete() {
    const { deleteTranslationAction, goToList } = this.props;
    deleteTranslationAction().then(() => goToList());
  }

  decrypt(url) {
    return cryptLib.decryptCipherTextWithRandomIV(url, 'hello_secret_key');
  }

  render() {
    const { submitted } = this.state;
    const { translation } = this.props;
    if (!translation || Object.keys(translation).length === 0) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleDelete} failedSubmit={this.failedDelete} />
        }
        <Row>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <strong>
                  {'Translation ID: '}
                  {translation.translationID}
                </strong>
                <div className="card-header-actions">
                  <Can I="update" a="Translation">
                    <Button href={`/#/translations/${translation.translationID}/edit`} color="link" className="card-header-action btn-setting">
                      <i className="icon-note" />
                    </Button>
                  </Can>
                  <Can I="delete" a="Translation">
                    <Button color="link" className="card-header-action btn-setting" onClick={e => this.requestDelete(e)}>
                      <i className="icon-trash" />
                    </Button>
                  </Can>
                </div>
              </CardHeader>
              <CardBody>
                <Table responsive striped hover>
                  <tbody>
                    <tr key="translationID">
                      <td>Translation ID:</td>
                      <td><strong>{translation.translationID}</strong></td>
                    </tr>
                    <tr key="translationName">
                      <td>Translation Name:</td>
                      <td><strong>{translation.translationName}</strong></td>
                    </tr>
                    <tr key="language">
                      <td>Language:</td>
                      <td><strong>{translation.language}</strong></td>
                    </tr>
                    <tr key="imageUrl">
                      <td>Image Url:</td>
                      <td><strong>{this.decrypt(translation.imageUrl)}</strong></td>
                    </tr>
                    <tr key="fileUrl">
                      <td>File Url:</td>
                      <td><strong>{this.decrypt(translation.fileUrl)}</strong></td>
                    </tr>
                    <tr key="translatorName">
                      <td>Translator Name:</td>
                      <td><strong>{translation.translatorName}</strong></td>
                    </tr>
                    <tr key="titleEN">
                      <td>Title En:</td>
                      <td><strong>{translation.titleEN}</strong></td>
                    </tr>
                    <tr key="titleAR">
                      <td>Title Ar:</td>
                      <td><strong>{translation.titleAR}</strong></td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter>
                <Button href={`/#/translations/${translation.translationID}/edit`} color="secondary" size="sm">Edit</Button>
                {' '}
                <Button color="danger" onClick={e => this.requestDelete(e)} size="sm">Delete</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

Translation.propTypes = {
  deleteTranslationAction: PropTypes.func.isRequired,
  getTranslation: PropTypes.func.isRequired,
  goToList: PropTypes.func.isRequired,
  translation: PropTypes.shape,
};

Translation.defaultProps = {
  translation: {},
};

const mapStateToProps = ({ translations }) => ({
  translation: translations.item,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  deleteTranslationAction: () => deleteTranslation(ownProps.match.params.id),
  getTranslation: () => fetchTranslation(ownProps.match.params.id),
  goToList: () => goBack(),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Translation);
