import Home from './containers/home';
import Reciter from './containers/reciters/show';
import ReciterEdit from './containers/reciters/edit';
import ReciterNew from './containers/reciters/new';
import Reciters from './containers/reciters';
import Sura from './containers/suras/show';
import SuraEdit from './containers/suras/edit';
import SuraNew from './containers/suras/new';
import Translation from './containers/translations/show';
import TranslationNew from './containers/translations/new';
import TranslationEdit from './containers/translations/edit';
import Translations from './containers/translations';

const routes = [
  {
    path: '/', exact: true, name: 'Home', component: Home,
  },
  {
    path: '/reciters', exact: true, name: 'Reciters', component: Reciters,
  },
  {
    path: '/reciters/new', exact: true, name: 'Reciter New', component: ReciterNew,
  },
  {
    path: '/reciters/:id', exact: true, name: 'Reciter Details', component: Reciter,
  },
  {
    path: '/reciters/:id/edit', exact: true, name: 'Reciter Edit', component: ReciterEdit,
  },
  {
    path: '/reciters/:id/new-sura', exact: true, name: 'Sura New', component: SuraNew,
  },
  {
    path: '/suras/:id', exact: true, name: 'Sura Details', component: Sura,
  },
  {
    path: '/suras/:id/edit', exact: true, name: 'Sura Edit', component: SuraEdit,
  },
  {
    path: '/translations', exact: true, name: 'Translations', component: Translations,
  },
  {
    path: '/transaltions/new', exact: true, name: 'Reciter New', component: TranslationNew,
  },
  {
    path: '/translations/:id', exact: true, name: 'Translation Details', component: Translation,
  },
  {
    path: '/translations/:id/edit', exact: true, name: 'Translation Edit', component: TranslationEdit,
  },
];

export default routes;
