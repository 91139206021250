/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { createTranslation } from '../../actions/translations';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';

class TranslationNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.requestSubmit = this.requestSubmit.bind(this);
    this.failedSubmit = this.failedSubmit.bind(this);
  }

  handleChange(e, name) {
    const { value } = e.target;
    this.setState({ [name]: value });
  }

  handleFileChange(e, name) {
    const { files } = e.target;
    this.setState({ [name]: files[0] });
  }

  requestSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedSubmit() {
    this.setState({
      submitted: false,
    });
  }

  handleSubmit() {
    const { createTranslationAction, goToList, resetErrorAction } = this.props;
    resetErrorAction();
    const {
      fileUrl,
      imageUrl,
      translationName,
      language,
      translatorName,
      titleEN,
      titleAR,
    } = this.state;
    if (!fileUrl && !imageUrl && !translationName
      && !language && !translatorName && !titleEN && !titleAR) {
      return;
    }
    const body = {
      translationName,
      language,
      translatorName,
      titleEN,
      titleAR,
    };
    const formData = new FormData();
    formData.append('imageUrl', imageUrl);
    formData.append('fileUrl', fileUrl);
    formData.append('body', JSON.stringify(body));
    createTranslationAction(formData).then(() => {
      goToList();
    });
  }

  render() {
    const {
      fileUrl,
      imageUrl,
      translationName,
      language,
      translatorName,
      titleEN,
      titleAR,
      submitted,
    } = this.state;
    const { goToList } = this.props;
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleSubmit} failedSubmit={this.failedSubmit} />
        }
        <Row>
          <Col lg={6}>
            <Card>
              <Form onSubmit={e => this.requestSubmit(e)} encType="multipart/form-data">
                <CardHeader>
                  <strong>
                    {'New translation'}
                  </strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="fileUrl">Select .json file</Label>
                    <Input
                      id="fileUrl"
                      type="file"
                      invalid={submitted && !fileUrl}
                      onChange={e => this.handleFileChange(e, 'fileUrl')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="imageUrl">Select translation flag</Label>
                    <Input
                      id="imageUrl"
                      type="file"
                      invalid={submitted && !imageUrl}
                      onChange={e => this.handleFileChange(e, 'imageUrl')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="translationName">Translation Name</Label>
                    <Input
                      id="translationName"
                      type="text"
                      placeholder="Enter Translation Name"
                      invalid={submitted && !translationName}
                      onChange={e => this.handleChange(e, 'translationName')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="language">Language</Label>
                    <Input
                      id="language"
                      type="text"
                      placeholder="Enter Language"
                      invalid={submitted && !language}
                      onChange={e => this.handleChange(e, 'language')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="translatorName">Translator Name</Label>
                    <Input
                      id="translatorName"
                      type="text"
                      placeholder="Enter Translator Name"
                      invalid={submitted && !translatorName}
                      onChange={e => this.handleChange(e, 'translatorName')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="titleEN">Title EN</Label>
                    <Input
                      id="titleEN"
                      type="text"
                      placeholder="Enter titleEN"
                      invalid={submitted && !titleEN}
                      onChange={e => this.handleChange(e, 'titleEN')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="titleAR">Title AR</Label>
                    <Input
                      id="titleAR"
                      type="text"
                      placeholder="Enter titleAR"
                      invalid={submitted && !titleAR}
                      onChange={e => this.handleChange(e, 'titleAR')}
                      required
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="primary">Create</Button>
                  {' '}
                  <Button onClick={() => goToList()} size="sm" color="danger">Cancel</Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

TranslationNew.propTypes = {
  createTranslationAction: PropTypes.func.isRequired,
  goToList: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  createTranslationAction: body => createTranslation(body),
  goToList: () => push('/translations'),
  resetErrorAction: () => resetError(),
}, dispatch);

export default connect(
  null,
  mapDispatchToProps,
)(TranslationNew);
