import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cryptLib from '@skavinvarnan/cryptlib';

class SuraRow extends Component {
  link() {
    const { sura } = this.props;
    return `/suras/${sura.suraID}`;
  }

  decrypt(url) {
    return cryptLib.decryptCipherTextWithRandomIV(url, 'hello_secret_key');
  }

  render() {
    const { sura } = this.props;
    return (
      <tr key={sura.suraID.toString()}>
        <th scope="row"><Link to={this.link()}>{sura.suraID}</Link></th>
        <td>{sura.TitleEn}</td>
        <td><a target="_blank" rel="noopener noreferrer" href={this.decrypt(sura.url)}>{this.decrypt(sura.url)}</a></td>
        <td>{sura.Number}</td>
        <td>{sura.Duration}</td>
      </tr>
    );
  }
}

SuraRow.propTypes = {
  sura: PropTypes.oneOf(PropTypes.object),
};

SuraRow.defaultProps = {
  sura: {},
};

export default SuraRow;
