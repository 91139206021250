import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardHeader, Col, Row, Table,
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchReciters } from '../../actions/reciters';

import ReciterRow from './row';

class Reciters extends React.Component {
  componentWillMount() {
    const { getReciters } = this.props;
    getReciters();
  }

  render() {
    const { reciters, loading } = this.props;
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xl={12}>
            <Card>
              <CardHeader>
                <Row>
                  <Col lg={4}>
                    Reciters
                  </Col>
                  <Col lg={8} style={{ textAlign: 'right' }}>
                    <Button href="/#/reciters/new" color="success" size="sm">New</Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col">Guid</th>
                      <th scope="col">Title En</th>
                      <th scope="col">Reciter Label</th>
                      <th scope="col">Timing</th>
                      <th scope="col">Hidden</th>
                      <th scope="col">Featured</th>
                      <th scope="col">Image Url</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading && reciters.length === 0 && <tr className="row-no-data"><td colSpan="4">No data found</td></tr>}
                    {reciters.map(reciter => <ReciterRow key={reciter.guid} reciter={reciter} />)}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

Reciters.propTypes = {
  getReciters: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  reciters: PropTypes.arrayOf(PropTypes.object),
};

Reciters.defaultProps = {
  reciters: [],
};

const mapStateToProps = ({ reciters }) => ({
  loading: reciters.loading,
  reciters: reciters.items,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getReciters: () => fetchReciters(),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Reciters);
