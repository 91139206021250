import { responseError } from './errors';

require('dotenv').config();

const ENV = process.env;

export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const RECEIVE_LOGIN = 'RECEIVE_LOGIN';
export const REQUEST_LOGOUT = 'REQUEST_LOGOUT';
export const REQUEST_ACCOUNT = 'REQUEST_ACCOUNT';
export const RECEIVE_ACCOUNT = 'RECEIVE_ACCOUNT';

export const authHeader = () => {};

export const localLogin = (email, password, keep) => ({
  type: RECEIVE_LOGIN,
  email,
  password,
  keep,
});

export const requestLogin = () => ({
  type: REQUEST_LOGIN,
});

export const receivedLogin = (username, token, permissions, keep) => ({
  type: RECEIVE_LOGIN,
  username,
  token,
  keep,
  permissions,
});

export const requestLogout = () => ({
  type: REQUEST_LOGOUT,
});

export const requestAccount = () => ({
  type: REQUEST_ACCOUNT,
});

export const receivedAccount = json => ({
  type: RECEIVE_ACCOUNT,
  json,
});


export const logout = () => (
  (dispatch) => {
    sessionStorage.removeItem('user');
    localStorage.removeItem('user');
    dispatch(requestLogout());
  }
);

export const login = (username, password, keep) => (
  (dispatch) => {
    dispatch(requestLogin());
    const headers = { 'Content-Type': 'application/json' };
    return fetch(`${ENV.REACT_APP_API_SERVER}/login`, { method: 'POST', headers, body: JSON.stringify({ username, password }) })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('User or password incorrect'));
        } else {
          dispatch(receivedLogin(username, json.token, json.permissions, keep));
        }
      });
  }
);

export const authorizedRequest = (url, options) => {
  const token = localStorage.getItem('token');
  const headers = {
    ...options.headers,
    authorization: token,
  };
  return fetch(url, { ...options, headers })
    .then(
      response => (response.status === 200 ? response.json() : response),
      error => error,
    )
    .then(json => json);
};
