import { camelizeKeys } from 'humps';

import {
  REQUEST_RECITERS, RECEIVE_RECITERS, RECEIVE_RECITER,
} from '../actions/reciters';

const reducer = (state = {
  items: [],
  loading: false,
  item: {},
}, action) => {
  switch (action.type) {
    case REQUEST_RECITERS:
      return { ...state, loading: true };
    case RECEIVE_RECITERS:
      return { ...state, items: camelizeKeys(action.json.data), loading: false };
    case RECEIVE_RECITER:
      return {
        ...state,
        item: action.json,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
