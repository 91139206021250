/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { createReciter } from '../../actions/reciters';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';

class ReciterNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.requestSubmit = this.requestSubmit.bind(this);
    this.failedSubmit = this.failedSubmit.bind(this);
  }

  handleChange(e, name) {
    const { value } = e.target;
    if (name === 'hidden' || name === 'featured') {
      this.setState({ [name]: value === 'true' });
      if (name === 'featured' && value === 'false') {
        this.setState({ featureCover: null });
        this.setState({ featuredTitleColor: null });
        this.setState({ featuredOrder: 0 });
      }
    } else {
      this.setState({ [name]: value });
    }
  }

  handleFileChange(e, name) {
    const { files } = e.target;
    this.setState({ [name]: files[0] });
  }

  requestSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedSubmit() {
    this.setState({
      submitted: false,
    });
  }

  handleSubmit() {
    const { createReciterAction, goToList, resetErrorAction } = this.props;
    resetErrorAction();
    const {
      plist,
      image_url,
      order,
      hidden,
      featured,
      featureCover,
      featuredTitleColor,
      featuredOrder,
      timing,
      timingLink,
      timingVersion,
      partial,
    } = this.state;
    if (!plist && !image_url && !order) {
      return;
    }
    const formData = new FormData();
    formData.append('plist', plist);
    formData.append('image_url', image_url);
    formData.append('order', order);
    if (hidden) {
      formData.append('hidden', hidden);
    }
    if (featured) {
      formData.append('featured', featured);
    }
    if (featureCover) {
      formData.append('featureCover', featureCover);
    }
    if (featuredTitleColor) {
      formData.append('featuredTitleColor', featuredTitleColor);
    }
    if (featuredOrder) {
      formData.append('featuredOrder', featuredOrder);
    }
    if (timing) {
      formData.append('timing', timing);
    }
    if (timingLink) {
      formData.append('timingLink', timingLink);
    }
    if (timingVersion) {
      formData.append('timingVersion', timingVersion);
    }
    if (partial) {
      formData.append('partial', partial);
    }
    createReciterAction(formData).then(() => {
      goToList();
    });
  }

  render() {
    const {
      plist,
      image_url,
      order,
      hidden,
      featured,
      featuredOrder,
      timing,
      timingVersion,
      partial,
      submitted,
    } = this.state;
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleSubmit} failedSubmit={this.failedSubmit} />
        }
        <Row>
          <Col lg={6}>
            <Card>
              <Form onSubmit={e => this.requestSubmit(e)} encType="multipart/form-data">
                <CardHeader>
                  <strong>
                    {'New reciter'}
                  </strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="plist">Select .plist file</Label>
                    <Input
                      id="plist"
                      type="file"
                      invalid={submitted && !plist}
                      onChange={e => this.handleFileChange(e, 'plist')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="image_url">Select Image file</Label>
                    <Input
                      id="image_url"
                      type="file"
                      invalid={submitted && !image_url}
                      onChange={e => this.handleFileChange(e, 'image_url')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="order">Order</Label>
                    <Input
                      id="order"
                      type="number"
                      placeholder="Enter order"
                      invalid={submitted && !order}
                      onChange={e => this.handleChange(e, 'order')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="hidden">Hidden</Label>
                    <Input
                      type="select"
                      name="hidden"
                      id="hidden"
                      onChange={e => this.handleChange(e, 'hidden')}
                    >
                      <option selected={hidden}>true</option>
                      <option selected={!hidden}>false</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="featured">Featured</Label>
                    <Input
                      type="select"
                      name="featured"
                      id="featured"
                      onChange={e => this.handleChange(e, 'featured')}
                    >
                      <option selected={featured}>true</option>
                      <option selected={!featured}>false</option>
                    </Input>
                  </FormGroup>
                  {
                    featured
                    && (
                    <FormGroup>
                      <Label htmlFor="image_url">Select Feature Cover</Label>
                      <Input
                        id="featureCover"
                        type="file"
                        invalid={submitted}
                        onChange={e => this.handleFileChange(e, 'featureCover')}
                        required
                      />
                    </FormGroup>
                    )
                  }
                  {
                    featured
                    && (
                    <FormGroup>
                      <Label htmlFor="featuredTitleColor">Featured Title Color</Label>
                      <Input
                        id="featuredTitleColor"
                        type="text"
                        placeholder="Enter Featured Title Color"
                        invalid={submitted}
                        onChange={e => this.handleChange(e, 'featuredTitleColor')}
                        required
                      />
                    </FormGroup>
                    )
                  }
                  {
                    featured
                    && (
                    <FormGroup>
                      <Label htmlFor="order">Featured Order</Label>
                      <Input
                        id="featuredOrder"
                        type="number"
                        placeholder="Enter featured order"
                        invalid={submitted && !featuredOrder}
                        onChange={e => this.handleChange(e, 'featuredOrder')}
                        required
                      />
                    </FormGroup>
                    )
                  }
                  <FormGroup>
                    <Label for="timing">Timing</Label>
                    <Input
                      type="select"
                      name="timing"
                      id="timing"
                      onChange={e => this.handleChange(e, 'timing')}
                    >
                      <option selected={timing}>true</option>
                      <option selected={!timing}>false</option>
                    </Input>
                  </FormGroup>
                  {
                    timing
                    && (
                    <FormGroup>
                      <Label htmlFor="timingVersion">Timing Version</Label>
                      <Input
                        id="timingVersion"
                        type="number"
                        placeholder="Enter timing version"
                        invalid={submitted && !timingVersion}
                        onChange={e => this.handleChange(e, 'timingVersion')}
                        defaultValue={1}
                        required
                      />
                    </FormGroup>
                    )
                  }
                  {
                    timing
                    && (
                    <FormGroup>
                      <Label htmlFor="timingLink">Select Timing Link</Label>
                      <Input
                        id="timingLink"
                        type="file"
                        invalid={submitted}
                        onChange={e => this.handleFileChange(e, 'timingLink')}
                        required
                      />
                    </FormGroup>
                    )
                  }
                  <FormGroup>
                    <Label for="partial">Partial</Label>
                    <Input
                      type="select"
                      name="partial"
                      id="partial"
                      onChange={e => this.handleChange(e, 'partial')}
                    >
                      <option selected={partial}>true</option>
                      <option selected={!partial}>false</option>
                    </Input>
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="primary">Create</Button>
                  {' '}
                  <Button href="/#/reciters" size="sm" color="danger">Cancel</Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

ReciterNew.propTypes = {
  createReciterAction: PropTypes.func.isRequired,
  goToList: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  createReciterAction: body => createReciter(body),
  goToList: () => push('/reciters'),
  resetErrorAction: () => resetError(),
}, dispatch);

export default connect(
  null,
  mapDispatchToProps,
)(ReciterNew);
