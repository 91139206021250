// import { decamelizeKeys } from 'humps';

import { authorizedRequest, logout } from './account';
import { responseError } from './errors';

require('dotenv').config();

const ENV = process.env;

export const REQUEST_SURAS = 'REQUEST_SURAS';
export const RECEIVE_SURAS = 'RECEIVE_SURAS';
export const RECEIVE_SURA = 'RECEIVE_SURA';

export const requestSuras = () => ({
  type: REQUEST_SURAS,
});

export const receivedSuras = json => ({
  type: RECEIVE_SURAS,
  json,
});

export const receivedSura = json => ({
  type: RECEIVE_SURA,
  json,
});

export const createSura = (id, params) => (
  (dispatch) => {
    dispatch(requestSuras());
    const headers = {};
    headers['Content-Type'] = 'application/json';
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/suras`, { method: 'POST', headers, body: JSON.stringify({ id, sura: params }) })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Sura or password incorrect'));
        }
        return json;
      });
  }
);

export const deleteSura = id => (
  (dispatch) => {
    dispatch(requestSuras());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/suras/${id}`, { method: 'DELETE', headers: {} })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Sura or password incorrect'));
        }
        return json;
      });
  }
);

export const fetchSuras = () => (
  (dispatch) => {
    dispatch(requestSuras());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/all`, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
          dispatch(receivedSuras([]));
        } else if (json.status === 401) {
          dispatch(logout());
          dispatch(receivedSuras([]));
        } else {
          dispatch(receivedSuras({ data: json.suras } || []));
        }
        return json.suras;
      });
  }
);

export const fetchSura = suraId => (
  (dispatch) => {
    dispatch(requestSuras());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/suras/${suraId}`, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(logout());
        } else {
          dispatch(receivedSura(json.sura));
        }
        return json.sura;
      });
  }
);

export const saveSura = (id, params) => (
  (dispatch) => {
    dispatch(requestSuras());
    const headers = {};
    headers['Content-Type'] = 'application/json';
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/suras/${id}`, { method: 'PUT', headers, body: JSON.stringify(params) })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Sura or password incorrect'));
        }
        return json;
      });
  }
);
