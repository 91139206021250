import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class ReciterRow extends Component {
  link() {
    const { reciter } = this.props;
    return `/reciters/${reciter.guid}`;
  }

  render() {
    const { reciter } = this.props;
    return (
      <tr key={reciter.guid.toString()}>
        <th scope="row"><Link to={this.link()}>{reciter.guid}</Link></th>
        <td><Link to={this.link()}>{reciter.titleEN}</Link></td>
        <td><Link to={this.link()}>{reciter.recitorLabel}</Link></td>
        <td>{reciter.timing.toString()}</td>
        <td>{reciter.hidden.toString()}</td>
        <td>{reciter.featured.toString()}</td>
        <td>{reciter.imageUrl}</td>
      </tr>
    );
  }
}

ReciterRow.propTypes = {
  reciter: PropTypes.oneOf(PropTypes.object),
};

ReciterRow.defaultProps = {
  reciter: {},
};

export default ReciterRow;
