import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Row, Table,
} from 'reactstrap';
import cryptLib from '@skavinvarnan/cryptlib';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import { deleteSura, fetchSura } from '../../actions/suras';
import Can from '../../components/can';
import PinModal from '../../components/pin';

class Sura extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.requestDelete = this.requestDelete.bind(this);
    this.failedDelete = this.failedDelete.bind(this);
  }

  componentWillMount() {
    const { getSura } = this.props;
    getSura();
  }

  requestDelete(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedDelete() {
    this.setState({
      submitted: false,
    });
  }

  handleDelete() {
    const { deleteSuraAction, goToReciter } = this.props;
    deleteSuraAction().then(() => goToReciter());
  }

  decrypt(url) {
    return cryptLib.decryptCipherTextWithRandomIV(url, 'hello_secret_key');
  }

  render() {
    const { submitted } = this.state;
    const { sura } = this.props;
    if (!sura || Object.keys(sura).length === 0) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleDelete} failedSubmit={this.failedDelete} />
        }
        <Row>
          <Col lg={6}>
            <Card>
              <CardHeader>
                <strong>
                  {'Sura ID: '}
                  {sura.suraID}
                </strong>
                <div className="card-header-actions">
                  <Can I="update" a="Sura">
                    <Button href={`/#/suras/${sura.suraID}/edit`} color="link" className="card-header-action btn-setting">
                      <i className="icon-note" />
                    </Button>
                  </Can>
                  <Can I="delete" a="Sura">
                    <Button color="link" className="card-header-action btn-setting" onClick={e => this.requestDelete(e)}>
                      <i className="icon-trash" />
                    </Button>
                  </Can>
                </div>
              </CardHeader>
              <CardBody>
                <Table responsive striped hover>
                  <tbody>
                    <tr key="suraID">
                      <td>Sura ID</td>
                      <td><strong>{sura.suraID}</strong></td>
                    </tr>
                    <tr key="TitleEn">
                      <td>Title En</td>
                      <td><strong>{sura.TitleEn}</strong></td>
                    </tr>
                    <tr key="TitleAr">
                      <td>Title Ar</td>
                      <td><strong>{sura.TitleAr}</strong></td>
                    </tr>
                    <tr key="url">
                      <td>Url</td>
                      <td><strong>{this.decrypt(sura.url)}</strong></td>
                    </tr>
                    <tr key="Number">
                      <td>Number</td>
                      <td><strong>{sura.Number}</strong></td>
                    </tr>
                    <tr key="Duration">
                      <td>Duration</td>
                      <td><strong>{sura.Duration}</strong></td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter>
                <Button href={`/#/suras/${sura.suraID}/edit`} color="secondary" size="sm">Edit</Button>
                {' '}
                <Button color="danger" onClick={e => this.requestDelete(e)} size="sm">Delete</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

Sura.propTypes = {
  deleteSuraAction: PropTypes.func.isRequired,
  getSura: PropTypes.func.isRequired,
  goToReciter: PropTypes.func.isRequired,
  sura: PropTypes.shape,
};

Sura.defaultProps = {
  sura: {},
};

const mapStateToProps = ({ suras }) => ({
  sura: suras.item,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  deleteSuraAction: () => deleteSura(ownProps.match.params.id),
  getSura: () => fetchSura(ownProps.match.params.id),
  goToReciter: () => goBack(),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Sura);
