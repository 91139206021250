// import { decamelizeKeys } from 'humps';

import { authorizedRequest, logout } from './account';
import { responseError } from './errors';

require('dotenv').config();

const ENV = process.env;

export const REQUEST_TRANSLATIONS = 'REQUEST_TRANSLATIONS';
export const RECEIVE_TRANSLATIONS = 'RECEIVE_TRANSLATIONS';
export const RECEIVE_TRANSLATION = 'RECEIVE_TRANSLATION';

export const requestTranslations = () => ({
  type: REQUEST_TRANSLATIONS,
});

export const receivedTranslations = json => ({
  type: RECEIVE_TRANSLATIONS,
  json,
});

export const receivedTranslation = json => ({
  type: RECEIVE_TRANSLATION,
  json,
});

export const createTranslation = params => (
  (dispatch) => {
    dispatch(requestTranslations());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/translations`, { method: 'POST', body: params })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Translation or password incorrect'));
        }
        return json;
      });
  }
);

export const deleteTranslation = id => (
  (dispatch) => {
    dispatch(requestTranslations());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/translations/${id}`, { method: 'DELETE', headers: {} })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Translation or password incorrect'));
        }
        return json;
      });
  }
);

export const fetchTranslations = () => (
  (dispatch) => {
    dispatch(requestTranslations());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/translations`, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
          dispatch(receivedTranslations([]));
        } else if (json.status === 401) {
          dispatch(logout());
          dispatch(receivedTranslations([]));
        } else {
          dispatch(receivedTranslations({ data: json.translations } || []));
        }
        return json.translations;
      });
  }
);

export const fetchTranslation = translationId => (
  (dispatch) => {
    dispatch(requestTranslations());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/translations/${translationId}`, { method: 'GET' })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(logout());
        } else {
          dispatch(receivedTranslation(json.translation));
        }
        return json.translation;
      });
  }
);

export const saveTranslation = (id, params) => (
  (dispatch) => {
    dispatch(requestTranslations());
    return authorizedRequest(`${ENV.REACT_APP_API_SERVER}/translations/${id}`, { method: 'POST', body: params })
      .then(
        response => (response.status === 200 ? response.json() : response),
        error => error,
      )
      .then((json) => {
        if (String(json).includes('TypeError')) {
          dispatch(responseError('Problems with server'));
        } else if (json.status === 401) {
          dispatch(responseError('Translation or password incorrect'));
        }
        return json;
      });
  }
);
