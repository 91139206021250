import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Row, Table,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { deleteReciter, fetchReciter } from '../../actions/reciters';
import Can from '../../components/can';
import SuraRow from '../suras/row';
import PinModal from '../../components/pin';

class Reciter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.requestDelete = this.requestDelete.bind(this);
    this.failedDelete = this.failedDelete.bind(this);
  }

  componentWillMount() {
    const { getReciter } = this.props;
    getReciter();
  }

  requestDelete(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedDelete() {
    this.setState({
      submitted: false,
    });
  }

  handleDelete() {
    const { deleteReciterAction, goToList } = this.props;
    deleteReciterAction().then(() => goToList());
  }

  render() {
    const { submitted } = this.state;
    const { reciter, createSura } = this.props;
    if (!reciter || Object.keys(reciter).length === 0) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleDelete} failedSubmit={this.failedDelete} />
        }
        <Row>
          <Col lg={4}>
            <Card>
              <CardHeader>
                <strong>
                  {'Reciter Guid: '}
                  {reciter.reciter.Guid}
                </strong>
                <div className="card-header-actions">
                  <Can I="update" a="Reciter">
                    <Button href={`/#/reciters/${reciter.reciter.Guid}/edit`} color="link" className="card-header-action btn-setting">
                      <i className="icon-note" />
                    </Button>
                  </Can>
                  <Can I="delete" a="Reciter">
                    <Button color="link" className="card-header-action btn-setting" onClick={e => this.requestDelete(e)}>
                      <i className="icon-trash" />
                    </Button>
                  </Can>
                </div>
              </CardHeader>
              <CardBody>
                <Table responsive striped hover>
                  <tbody>
                    <tr key="titleEN">
                      <td>Title En</td>
                      <td><strong>{reciter.reciter.TitleEN}</strong></td>
                    </tr>
                    <tr key="titleAR">
                      <td>Title Ar</td>
                      <td><strong>{reciter.reciter.TitleAR}</strong></td>
                    </tr>
                    <tr key="recitorLabel">
                      <td>Reciter Label</td>
                      <td><strong>{reciter.reciter.RecitorLabel}</strong></td>
                    </tr>
                    <tr key="image_url">
                      <td>Image Url</td>
                      <td><strong>{reciter.reciter.image_url}</strong></td>
                    </tr>
                    <tr key="hidden">
                      <td>Hidden</td>
                      <td><strong>{reciter.reciter.hidden.toString()}</strong></td>
                    </tr>
                    <tr key="featured">
                      <td>Featured</td>
                      <td><strong>{reciter.reciter.featured.toString()}</strong></td>
                    </tr>
                    <tr key="featureCover">
                      <td>Feature Cover</td>
                      <td><strong>{reciter.reciter.featureCover}</strong></td>
                    </tr>
                    <tr key="timing">
                      <td>Timing</td>
                      <td><strong>{reciter.reciter.timing.toString()}</strong></td>
                    </tr>
                    <tr key="timingVersion">
                      <td>Timing Version</td>
                      <td><strong>{reciter.reciter.timingVersion}</strong></td>
                    </tr>
                    <tr key="timingLink">
                      <td>Timing Link</td>
                      <td><strong>{reciter.reciter.timingLink}</strong></td>
                    </tr>
                    <tr key="partial">
                      <td>Partial</td>
                      <td><strong>{reciter.reciter.partial.toString()}</strong></td>
                    </tr>
                    <tr key="order">
                      <td>Order</td>
                      <td><strong>{reciter.reciter.order}</strong></td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter>
                <Button href={`/#/reciters/${reciter.reciter.Guid}/edit`} color="secondary" size="sm">Edit</Button>
                {' '}
                <Button color="danger" onClick={e => this.requestDelete(e)} size="sm">Delete</Button>
              </CardFooter>
            </Card>
          </Col>
          <Col lg={8}>
            <Card style={{ maxHeight: 700, overflowY: 'scroll' }}>
              <CardHeader>
                <Row>
                  <Col lg={4}>
                    Suras
                  </Col>
                  <Col lg={8} style={{ textAlign: 'right' }}>
                    <Button onClick={() => createSura()} color="success" size="sm">New</Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col">Sura ID</th>
                      <th scope="col">Title En</th>
                      <th scope="col">Url</th>
                      <th scope="col">Number</th>
                      <th scope="col">Duration</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reciter.items.length === 0 && <tr className="row-no-data"><td colSpan="4">No data found</td></tr>}
                    {reciter.items.map(sura => <SuraRow key={sura.suraID} sura={sura} />)}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

Reciter.propTypes = {
  deleteReciterAction: PropTypes.func.isRequired,
  getReciter: PropTypes.func.isRequired,
  goToList: PropTypes.func.isRequired,
  createSura: PropTypes.func.isRequired,
  reciter: PropTypes.shape,
};

Reciter.defaultProps = {
  reciter: {},
};

const mapStateToProps = ({ reciters }) => ({
  reciter: reciters.item,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  deleteReciterAction: () => deleteReciter(ownProps.match.params.id),
  getReciter: () => fetchReciter(ownProps.match.params.id),
  createSura: () => push(`/reciters/${ownProps.match.params.id}/new-sura`),
  goToList: () => push('/reciters'),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Reciter);
