/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row,
} from 'reactstrap';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { createSura } from '../../actions/suras';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';

class SuraNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.requestSubmit = this.requestSubmit.bind(this);
    this.failedSubmit = this.failedSubmit.bind(this);
  }

  handleChange(e, name) {
    const { value } = e.target;
    this.setState({ [name]: value });
  }

  requestSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedSubmit() {
    this.setState({
      submitted: false,
    });
  }

  handleSubmit() {
    const { createSuraAction, goToReciter, resetErrorAction } = this.props;
    resetErrorAction();
    const {
      Link,
      Number,
      TitleEn,
      TitleAr,
      Duration,
      url,
    } = this.state;
    if (!Link && !Number && !TitleEn && !TitleAr && !Duration && !url) {
      return;
    }
    const body = {
      Link,
      Number,
      TitleEn,
      TitleAr,
      Duration,
      url,
    };
    createSuraAction(body).then(() => {
      goToReciter();
    });
  }

  render() {
    const {
      Link,
      Number,
      TitleEn,
      TitleAr,
      Duration,
      url,
      submitted,
    } = this.state;
    const { goToReciter } = this.props;
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleSubmit} failedSubmit={this.failedSubmit} />
        }
        <Row>
          <Col lg={6}>
            <Card>
              <Form onSubmit={e => this.requestSubmit(e)}>
                <CardHeader>
                  <strong>
                    {'New sura'}
                  </strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="Link">Link</Label>
                    <Input
                      id="Link"
                      type="text"
                      placeholder="Enter Link"
                      invalid={submitted && !Link}
                      onChange={e => this.handleChange(e, 'Link')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="Number">Number</Label>
                    <Input
                      id="Number"
                      type="text"
                      placeholder="Enter Number"
                      invalid={submitted && !Number}
                      onChange={e => this.handleChange(e, 'Number')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="TitleEn">Title En</Label>
                    <Input
                      id="TitleEn"
                      type="text"
                      placeholder="Enter Title En"
                      invalid={submitted && !TitleEn}
                      onChange={e => this.handleChange(e, 'TitleEn')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="TitleAr">Title Ar</Label>
                    <Input
                      id="TitleAr"
                      type="text"
                      placeholder="Enter Title Ar"
                      invalid={submitted && !TitleAr}
                      onChange={e => this.handleChange(e, 'TitleAr')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="Duration">Duration</Label>
                    <Input
                      id="Duration"
                      type="text"
                      placeholder="Enter Duration"
                      invalid={submitted && !Duration}
                      onChange={e => this.handleChange(e, 'Duration')}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="url">Url</Label>
                    <Input
                      id="url"
                      type="text"
                      placeholder="Enter Url"
                      invalid={submitted && !url}
                      onChange={e => this.handleChange(e, 'url')}
                      required
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="primary">Create</Button>
                  {' '}
                  <Button onClick={() => goToReciter()} size="sm" color="danger">Cancel</Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

SuraNew.propTypes = {
  createSuraAction: PropTypes.func.isRequired,
  goToReciter: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  createSuraAction: body => createSura(ownProps.match.params.id, body),
  goToReciter: () => push(`/reciters/${ownProps.match.params.id}`),
  resetErrorAction: () => resetError(),
}, dispatch);

export default connect(
  null,
  mapDispatchToProps,
)(SuraNew);
