/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row,
} from 'reactstrap';
import cryptLib from '@skavinvarnan/cryptlib';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { fetchTranslation, saveTranslation } from '../../actions/translations';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';

class TranslationEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.requestSubmit = this.requestSubmit.bind(this);
    this.failedSubmit = this.failedSubmit.bind(this);
  }

  componentWillMount() {
    const { getTranslation } = this.props;
    getTranslation();
  }

  handleChange(e, name) {
    const { value } = e.target;
    this.setState({ [name]: value });
  }

  handleFileChange(e, name) {
    const { files } = e.target;
    this.setState({ [name]: files[0] });
  }

  requestSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedSubmit() {
    this.setState({
      submitted: false,
    });
  }

  handleSubmit() {
    const { saveTranslationAction, goToShow, resetErrorAction } = this.props;
    resetErrorAction();
    const {
      translationName,
      language,
      imageUrl,
      fileUrl,
      translatorName,
      titleEN,
      titleAR,
    } = this.state;
    if (!translationName && !language && !imageUrl && !fileUrl
       && !translatorName && !titleEN && !titleAR) {
      goToShow();
      return;
    }
    const body = {
      translationName,
      language,
      translatorName,
      titleEN,
      titleAR,
    };
    Object.keys(body).forEach(key => body[key] === undefined && delete body[key]);
    const { translation } = this.props;
    const formData = new FormData();
    formData.append('imageUrl', imageUrl);
    formData.append('fileUrl', fileUrl);
    formData.append('body', JSON.stringify(body));
    saveTranslationAction(translation.translationID, formData).then(() => goToShow());
  }

  decrypt(url) {
    return cryptLib.decryptCipherTextWithRandomIV(url, 'hello_secret_key');
  }

  render() {
    const { translation } = this.props;
    const {
      translationName,
      language,
      imageUrl,
      fileUrl,
      translatorName,
      titleEN,
      titleAR,
      submitted,
    } = this.state;
    if (!translation || Object.keys(translation).length === 0) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleSubmit} failedSubmit={this.failedSubmit} />
        }
        <Row>
          <Col lg={8}>
            <Card>
              <Form onSubmit={e => this.requestSubmit(e)} encType="multipart/form-data">
                <CardHeader>
                  <strong>
                    {'Edit translation: '}
                    {translation.translationID}
                  </strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="fileUrl">.json file</Label>
                    <Input
                      id="fileUrlInput"
                      type="text"
                      defaultValue={this.decrypt(translation.fileUrl)}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="fileUrl">Upload new .json file</Label>
                    <Input
                      id="fileUrl"
                      type="file"
                      invalid={submitted && !fileUrl}
                      onChange={e => this.handleFileChange(e, 'fileUrl')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="imageUrl">Translation flag</Label>
                    <Input
                      id="imageUrlInput"
                      type="text"
                      defaultValue={this.decrypt(translation.imageUrl)}
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="imageUrl">Upload new translation flag</Label>
                    <Input
                      id="imageUrl"
                      type="file"
                      invalid={submitted && !imageUrl}
                      onChange={e => this.handleFileChange(e, 'imageUrl')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="translationName">Translation Name</Label>
                    <Input
                      id="translationName"
                      type="text"
                      placeholder="Enter Translation Name"
                      defaultValue={translation.translationName}
                      invalid={submitted && !translationName}
                      onChange={e => this.handleChange(e, 'translationName')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="language">Language</Label>
                    <Input
                      id="language"
                      type="text"
                      placeholder="Enter Language"
                      defaultValue={translation.language}
                      invalid={submitted && !language}
                      onChange={e => this.handleChange(e, 'language')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="translatorName">Translator Name</Label>
                    <Input
                      id="translatorName"
                      type="text"
                      placeholder="Enter Translator Name"
                      defaultValue={translation.translatorName}
                      invalid={submitted && !translatorName}
                      onChange={e => this.handleChange(e, 'translatorName')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="titleEN">Title En</Label>
                    <Input
                      id="titleEN"
                      type="text"
                      placeholder="Enter Title En"
                      defaultValue={translation.titleEN}
                      invalid={submitted && !titleEN}
                      onChange={e => this.handleChange(e, 'titleEN')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="titleAR">Title Ar</Label>
                    <Input
                      id="titleAR"
                      type="text"
                      placeholder="Enter Title Ar"
                      defaultValue={translation.titleAR}
                      invalid={submitted && !titleAR}
                      onChange={e => this.handleChange(e, 'titleAR')}
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="success">Save</Button>
                  {' '}
                  <Button href={`/#/translations/${translation.translationID}`} size="sm" color="danger">Cancel</Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

TranslationEdit.propTypes = {
  getTranslation: PropTypes.func.isRequired,
  goToShow: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
  saveTranslationAction: PropTypes.func.isRequired,
  translation: PropTypes.shape.isRequired,
};

const mapStateToProps = ({ translations }) => ({
  translation: translations.item,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  getTranslation: () => fetchTranslation(ownProps.match.params.id),
  goToShow: () => push(`/translations/${ownProps.match.params.id}`),
  resetErrorAction: () => resetError(),
  saveTranslationAction: (id, body) => saveTranslation(id, body),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TranslationEdit);
