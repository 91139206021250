import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import ReactCodeInput from 'react-code-input';

const ENV = process.env;

class PinModal extends React.Component {
  constructor(props) {
    super(props);
    this.pinChange = this.pinChange.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    const { failedSubmit } = this.props;
    failedSubmit();
  }

  pinChange(pin) {
    const { successSubmit, failedSubmit } = this.props;
    if (pin.length >= 4) {
      if (pin === ENV.REACT_APP_PIN_CODE) successSubmit();
      else failedSubmit();
    }
  }

  render() {
    return (
      <Modal isOpen toggle={this.toggle} autoFocus={false}>
        <ModalHeader toggle={this.toggle}>Pin Code Confirmation</ModalHeader>
        <ModalBody className="text-center">
          <ReactCodeInput
            type="password"
            fields={4}
            onChange={this.pinChange}
            autoComplete="off"
            autoFocus
          />
        </ModalBody>
      </Modal>
    );
  }
}

PinModal.propTypes = {
  successSubmit: PropTypes.func.isRequired,
  failedSubmit: PropTypes.func.isRequired,
};

export default PinModal;
