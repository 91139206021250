import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardHeader, Col, Row, Table,
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchTranslations } from '../../actions/translations';

import TranslationRow from './row';

class Translations extends React.Component {
  componentWillMount() {
    const { getTranslations } = this.props;
    getTranslations();
  }

  render() {
    const { translations, loading } = this.props;
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xl={12}>
            <Card>
              <CardHeader>
                <Row>
                  <Col lg={4}>
                    Translations
                  </Col>
                  <Col lg={8} style={{ textAlign: 'right' }}>
                    <Button href="/#/transaltions/new" color="success" size="sm">New</Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col">Translation ID</th>
                      <th scope="col">Translation Name</th>
                      <th scope="col">Language</th>
                      <th scope="col">File Url</th>
                      <th scope="col">Image Url</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading && translations.length === 0 && <tr className="row-no-data"><td colSpan="4">No data found</td></tr>}
                    {translations.map(translation => (
                      <TranslationRow key={translation.guid} translation={translation} />
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

Translations.propTypes = {
  getTranslations: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  translations: PropTypes.arrayOf(PropTypes.object),
};

Translations.defaultProps = {
  translations: [],
};

const mapStateToProps = ({ translations }) => ({
  loading: translations.loading,
  translations: translations.items,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getTranslations: () => fetchTranslations(),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Translations);
