import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cryptLib from '@skavinvarnan/cryptlib';

class TranslationRow extends Component {
  link() {
    const { translation } = this.props;
    return `/translations/${translation.translationID}`;
  }

  decrypt(url) {
    return cryptLib.decryptCipherTextWithRandomIV(url, 'hello_secret_key');
  }

  render() {
    const { translation } = this.props;
    return (
      <tr key={translation.translationID.toString()}>
        <th scope="row"><Link to={this.link()}>{translation.translationID}</Link></th>
        <td>{translation.translationName}</td>
        <td>{translation.language}</td>
        <td><a target="_blank" rel="noopener noreferrer" href={this.decrypt(translation.fileUrl)}>{this.decrypt(translation.fileUrl)}</a></td>
        <td><a target="_blank" rel="noopener noreferrer" href={this.decrypt(translation.imageUrl)}>{this.decrypt(translation.imageUrl)}</a></td>
      </tr>
    );
  }
}

TranslationRow.propTypes = {
  translation: PropTypes.oneOf(PropTypes.object),
};

TranslationRow.defaultProps = {
  translation: {},
};

export default TranslationRow;
