/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row,
} from 'reactstrap';
import cryptLib from '@skavinvarnan/cryptlib';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { fetchSura, saveSura } from '../../actions/suras';
import { resetError } from '../../actions/errors';
import PinModal from '../../components/pin';

class SuraEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.requestSubmit = this.requestSubmit.bind(this);
    this.failedSubmit = this.failedSubmit.bind(this);
  }

  componentWillMount() {
    const { getSura } = this.props;
    getSura();
  }

  handleChange(e, name) {
    const { value } = e.target;
    this.setState({ [name]: value });
  }

  requestSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
  }

  failedSubmit() {
    this.setState({
      submitted: false,
    });
  }

  handleSubmit() {
    const { saveSuraAction, goToShow, resetErrorAction } = this.props;
    resetErrorAction();
    const {
      Link,
      Number,
      TitleEn,
      TitleAr,
      Duration,
      url,
    } = this.state;
    if (!Link && !Number && !TitleEn && !TitleAr && !Duration && !url) {
      goToShow();
      return;
    }
    const body = {
      Link,
      Number,
      TitleEn,
      TitleAr,
      Duration,
      url,
    };
    Object.keys(body).forEach(key => body[key] === undefined && delete body[key]);
    const { sura } = this.props;
    saveSuraAction(sura.suraID, body).then(() => goToShow());
  }

  decrypt(url) {
    return cryptLib.decryptCipherTextWithRandomIV(url, 'hello_secret_key');
  }

  render() {
    const { sura } = this.props;
    const {
      Link,
      Number,
      TitleEn,
      TitleAr,
      Duration,
      url,
      submitted,
    } = this.state;
    if (!sura || Object.keys(sura).length === 0) {
      return (null);
    }
    return (
      <div className="animated fadeIn">
        {
          submitted
          && <PinModal successSubmit={this.handleSubmit} failedSubmit={this.failedSubmit} />
        }
        <Row>
          <Col lg={8}>
            <Card>
              <Form onSubmit={e => this.requestSubmit(e)}>
                <CardHeader>
                  <strong>
                    {'Edit sura: '}
                    {sura.email}
                  </strong>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="Link">Link</Label>
                    <Input
                      id="Link"
                      type="text"
                      placeholder="Enter Link"
                      defaultValue={sura.Link}
                      invalid={submitted && !Link}
                      onChange={e => this.handleChange(e, 'Link')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="Number">Number</Label>
                    <Input
                      id="Number"
                      type="text"
                      placeholder="Enter Number"
                      defaultValue={sura.Number}
                      invalid={submitted && !Number}
                      onChange={e => this.handleChange(e, 'Number')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="TitleEn">Title En</Label>
                    <Input
                      id="TitleEn"
                      type="text"
                      placeholder="Enter Title En"
                      defaultValue={sura.TitleEn}
                      invalid={submitted && !TitleEn}
                      onChange={e => this.handleChange(e, 'TitleEn')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="TitleAr">Title Ar</Label>
                    <Input
                      id="TitleAr"
                      type="text"
                      placeholder="Enter Title Ar"
                      defaultValue={sura.TitleAr}
                      invalid={submitted && !TitleAr}
                      onChange={e => this.handleChange(e, 'TitleAr')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="Duration">Duration</Label>
                    <Input
                      id="Duration"
                      type="text"
                      placeholder="Enter Duration"
                      defaultValue={sura.Duration}
                      invalid={submitted && !Duration}
                      onChange={e => this.handleChange(e, 'Duration')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="url">Url</Label>
                    <Input
                      id="url"
                      type="text"
                      placeholder="Enter Url"
                      defaultValue={this.decrypt(sura.url).replace(sura.Link, '')}
                      invalid={submitted && !url}
                      onChange={e => this.handleChange(e, 'url')}
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button type="submit" size="sm" color="success">Save</Button>
                  {' '}
                  <Button href={`/#/suras/${sura.suraID}`} size="sm" color="danger">Cancel</Button>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

SuraEdit.propTypes = {
  getSura: PropTypes.func.isRequired,
  goToShow: PropTypes.func.isRequired,
  resetErrorAction: PropTypes.func.isRequired,
  saveSuraAction: PropTypes.func.isRequired,
  sura: PropTypes.shape.isRequired,
};

const mapStateToProps = ({ suras }) => ({
  sura: suras.item,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  getSura: () => fetchSura(ownProps.match.params.id),
  goToShow: () => push(`/suras/${ownProps.match.params.id}`),
  resetErrorAction: () => resetError(),
  saveSuraAction: (id, body) => saveSura(id, body),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SuraEdit);
