import { combineReducers } from 'redux';
import account from './account';
import errors from './errors';
import users from './users';
import reciters from './reciters';
import suras from './suras';
import translations from './translations';

export default combineReducers({
  account,
  errors,
  users,
  reciters,
  suras,
  translations,
});
